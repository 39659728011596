import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './IconButton.module.scss';

const IconButton = ({ children, onClick, variant, disabled, className }) => (
  <button
    className={cn(styles.btn, { [styles[variant]]: variant }, className)}
    onClick={onClick}
    disabled={disabled}
    type="button"
  >
    {children}
  </button>
);

IconButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'cuetv']),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  variant: 'primary',
};

export default IconButton;
