import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';

export const withSnackbar = WrappedComponent => props => {
  const { enqueueSnackbar } = useSnackbar();

  const showMessage = useCallback(
    message => {
      enqueueSnackbar(message, {
        hasCloseIcon: false,
        hasVariantIcon: false,
      });
    },
    [enqueueSnackbar],
  );

  return <WrappedComponent {...props} snackbarShowMessage={showMessage} />;
};
